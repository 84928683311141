    export const background = require('./images/background.jpg')
    export const background2 = require('./images/background-2.jpg')
    export const background3 = require('./images/background-3.jpg')
    export const background4 = require('./images/background-4.jpg')
    export const background5 = require('./images/background-5.jpg')
    export const background6 = require('./images/background-6.jpg')
    export const background7 = require('./images/background-7.jpg')
    export const background8 = require('./images/background-8.jpg')
    export const shareImage = require('./images/share.jpg')
    export const branding = require('./images/branding.svg')
    export const overlay = './images/scratch.png';
    export const pocket = require('./images/pocketbook.jpg')
    export const logo = require('./images/logo.svg')
    export const favicon = require('./images/favicon.png')
    export const cta = require('./images/cta.png')
    export const promo = require('./images/promo.png')
    export const image = require('./images/image.svg')