import QRCode from 'qrcode';
async function generateQRCode(content) {
  try {
    const qrCodeDataUrl = await QRCode.toDataURL(content);
    const qrCodeImg = document.createElement('img');
    qrCodeImg.src = qrCodeDataUrl;

    const qrcodeContainer = document.getElementById('qrcode');
    qrcodeContainer.innerHTML = ''; // Clear any existing content
    qrcodeContainer.appendChild(qrCodeImg);
  } catch (error) {
    console.error('Error generating QR code:', error);
  }
}
generateQRCode(location.href)