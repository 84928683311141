export const firebaseConfig = {
    apiKey: "AIzaSyATDiBMGbsuc3vnsiJg02xO0sIhTCoTzcE",
    authDomain: "klikkie-prod.firebaseapp.com",
    databaseURL: "https://klikkie-prod.firebaseio.com",
    projectId: "klikkie-prod",
    storageBucket: "remember-this",
    messagingSenderId: "1093343370835",
    appId: "1:1093343370835:web:86f07cd0f109ab87c3f0a6",
    measurementId: "G-PV57YH017C"
    
};
