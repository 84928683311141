import Handlebars from 'handlebars';
export const data = {
    //  global
    loader: "Je fotokrabbel wordt geladen",
    qrCode: "Fotokrabbel werkt alleen op je mobiel.<br/> Scan de QR-code met je telefoon om te beginnen",
    createCard: 'Win een gratis fotoboekje!',
    //  index page
    greeting: 'Verras vrienden met een fotokrabbel <br />en ontvang een gratis fotoboekje!',
    startButton: "Start",
    //  create 
    addPhotoIntro: "Upload een leuke foto.",
    addPhoto: "Kies een foto",
    uploadDefaultState: 'Upload',
    uploadPreloadState: 'Uploaden..',
    chooseWrapper: "Kies een wrapper",
    shareCard: "Deel je fotokrabbel",
    //  congratulations
    congratsMessage: 'Gefeliciteerd!',
    shareCountMessage: "Deel nog <span id='shareCount'>10</span> fotokrabbels en ontvang een gratis pocket fotoboekje van klikkie",
    shareAgain:'Nieuwe fotokrabbel',
    //  gift 
    completedSharesMessage: "Wow! Je hebt 10 fotokrabbels gedeeld!",
    pocketBookMessage: "Download de klikkie app, bestel een fotoboekje t.w.v. €10,-* en voeg deze code toe:",
    sendMessage:"*Dit is exclusief het versturen.",
    appleButton: "download in de appstore",
    googleButton: "ontdek het op google play",
    // share message
    shareTitle:"Een fotokrabbel voor jou!",
    shareText:"Ik heb een fotokrabbel voor je gemaakt! Let op: deze link is 24 uur geldig.",
    //  scratch
    loadingScrathCard: "Je fotokrabbel wordt geladen..."
};
// Compileer de HTML met Handlebars
const source = document.querySelector('html').innerHTML;
const template = Handlebars.compile(source);
const html = template(data);
// Vervang de volledige HTML in de body
document.querySelector('html').innerHTML = html;