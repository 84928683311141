import './handlebars.js';
import './styles.css';
import './qrcode.js';

import * as images from './images.js';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import { firebaseConfig } from './config.js';
const app = initializeApp(firebaseConfig)
const analytics = getAnalytics(app);

const startButton =document.getElementById('startButton')
startButton.addEventListener('click', ()=> logEvent(analytics, "StartWithApp"))
